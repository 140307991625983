<template>
    <window-content style="overflow-y: scroll;">
        <div class="m-t-md">
            <div class="row erp-row">
                <div class="col" style="max-width: 190px;">
                    <erp-s-field
                            view="lr"
                            label="Código:"
                            label-width="95px"
                    >
                        <erp-input autofocus simple-border v-model="solicitacao.codigo" />
                    </erp-s-field>
                </div>

                <div class="col" style="max-width: 180px;">
                    <erp-s-field
                            view="ll"
                            label="Canal:"
                    >
                        <erp-select
                                placeholder="Qualquer"
                                :options="canal.serverData"
                                v-model="solicitacao.canal" />
                    </erp-s-field>
                </div>

                <div class="col" style="max-width: 384px">
                    <erp-s-field
                            view="ll"
                            label="Data da solicitação:"
                    >
                        <datetime-input v-model="solicitacao.dataSolicitacao" simpleBorder />
                    </erp-s-field>
                </div>
            </div>

            <div class="row erp-row erp-row-col-md m-t-sm m-b">
                <div class="col" style="max-width: 410px;">
                    <erp-s-field
                            view="lr"
                            label="Cliente:"
                            label-width="95px"
                    >
                        <erp-select
                                placeholder="Qualquer"
                                :options="cliente.serverData"
                                v-model="solicitacao.cliente" />
                    </erp-s-field>
                </div>

                <div class="col" style="max-width: 250px;">
                    <erp-s-field
                            view="ll"
                            label="Status:"
                    >
                        <erp-select
                                placeholder="Qualquer"
                                :options="status.serverData"
                                v-model="solicitacao.status" />
                    </erp-s-field>
                </div>
            </div>

        </div>

        <div>
            <erp-tabs @change="changeTab" :active="tabActive">
                <erp-tab-item name="solicitacao">Solicitação</erp-tab-item>
            </erp-tabs>

            <window-body tabs>
                <div class="wrapper-md">
                    <strong>BV Financeira escreveu:</strong>
                    <p>Favor recolher o veículo placa OLX2739 chassi O2X930476R0362019 para leilão. Veículo oriundo de apreensão por falta de pagamento, processo já
                        finalizado e liberado para ser leiloado.</p>

                    <p>Endereço</p>
                    <ul>
                        <li>Pátio da Detran</li>
                        <li>Av. Dulce Sarmento, 233, São José. </li>
                        <li>Montes Claros - MG</li>
                    </ul>
                    <p>Segue anexo o protocolo de retirada, contrato de financiamento e demais informações úteis.</p>

                    <strong>Anexos:</strong>
                </div>
            </window-body>
        </div>

        <window-footer class="text-right">
            <div class="inline-block window-actions">
                <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
                       @click="$uloc.window.close($root.wid)" />
                <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
                       @click="save"
                       v-shortkey="['f2']" @shortkey.native="save" />
            </div>
        </window-footer>
    </window-content>
</template>

<script>
    import { ErpTabs, ErpTabItem, WindowContent, WindowBody, WindowFooter } from 'uloc-vue-plugin-erp'
    import {
        ErpSField,
        ErpInput,
        ErpSelect
    } from 'uloc-vue-plugin-erp'
    import DatetimeInput from '../../../../reuse/input/Datetime'

    export default {
        name: 'ShowSolicitacao',
        data () {
            return {
                tabActive: 'solicitacao',
                canal: {
                    loading: true,
                    serverData: []
                },
                cliente: {
                    loading: true,
                    serverData: []
                },
                status: {
                    loading: true,
                    serverData: []
                },
                solicitacao: {
                    codigo: null,
                    canal: null,
                    dataSolicitacao: null,
                    cliente: null,
                    status: null
                }
            }
        },
        methods: {
            changeTab ({tab}) {
                this.tabActive = tab
            }
        },
        components: {
            ErpTabs,
            ErpTabItem,
            WindowContent,
            WindowBody,
            WindowFooter,
            ErpSField,
            ErpInput,
            ErpSelect,
            DatetimeInput
        }
    }
</script>
